.mobile-about__container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.mobile-about__information-img {
    width: 80%;
    height: auto;
}

.mobile-about__information {
    margin-top: -50%;
    font-size: 14px;
    color: white;
    line-height: 20px;
    width: 80%;
}

#about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .redX {
    position: absolute;
    display: block;
    top: 202px;
    left: -54px;
    z-index: -1;
}
}