

.mobile__summury-text{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color:#FFFFFF;
}

.mobile__summury__container{
    margin-top: 50px;
    align-self: center;
    padding: 0px;
}