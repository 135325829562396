@import '../../../styles/variables.scss';
//Новое введение
.merchandise_alert{
margin-right: -10px;
}




//Новое ввдение
.merchandise__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 50px;
}

.go-to-catalog {
    align-self: center;
    color: white;
    font-size: 24px;
    font-weight: 500;
    border: 2px solid white;
    padding: 24px 32px;
    text-decoration: none;
    margin-right: 40px;
    margin-bottom: 60px;

    &:hover {
        background-color: #222;
    }
}


.merchandise__list-item__buttons_go_to_basket{
    display: flex;
    justify-content: center;
    padding: 0px, 32px;

}

.merchandise__list-item__go_to_basket{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    display: inline-flex;
    background-color: white;
    padding: 17px 31px;
    border-radius: 8px;
    border: 2px solid #146DF2;
    color: #146DF2;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    

}