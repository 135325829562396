@import '../../../styles/variables.scss';

.mobile-musics__header-logo {
    background: center center / contain no-repeat url('../../../assets/images/medium_chertochki.png');
    width: 25%;
    height: 50px;
    align-self: center;
    margin-bottom: 30px;

    &-text {
        font-size: $mobile-concerts-header-font-size;
        font-weight: $header-font-weight;
        color: $header-font-color;
        margin: 0;
        margin-top: 10px;
        text-align: center;
    }
}