@import '../../styles/variables.scss';

.header-general-info {
    background: top / cover no-repeat url('../../assets/images/230213_Klepnev_10502x.jpg');
    background-position-y: 1px;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 1023px) {
    
}