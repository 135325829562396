
.wish__container{
    margin-top: 100px;
}
.wish-text{
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color:#FFFFFF;
}
