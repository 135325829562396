
@font-face {
    font-family: 'Factor A';
    src: url(./assets/fonts/FactorA-Regular-Web.woff2);
    src: local('Factor A Regular'), local('FactorA-Regular-Web'),
        url(./assets/fonts/FactorA-Regular-Web.woff2) format('woff2');
        font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Factor A';
    src: url(./assets/fonts/FactorA-Medium.woff2);
    src: local('Factor A Medium Regular'), local('FactorA-Medium'),
        url(./assets/fonts/FactorA-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Factor A';
    src: url(./assets/fonts/FactorA-Bold-Web.woff2);
    src: local('Factor A Bold'), local('FactorA-Bold-Web'),
        url(./assets/fonts/FactorA-Bold-Web.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}


/* W3C standard
   сейчас только для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {  
  background-color: #da7332;
  border-radius: 0px;
  border: 2.5px solid #da7332;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-x: hidden;
  background-color: #0F0F0F;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.chakra-toast {
  display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transform: none;
}
.chakra-toast__inner {
      pointer-events: auto;
    max-width: 560px;
    min-width: 300px;
    margin: 8px;
}
.chakra-alert {
  display: flex;
    position: relative;
    overflow: hidden;
    background: #2f855a;
    padding-inline-start: 16px;
    padding-inline-end: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
    --alert-fg: white;
    --alert-bg: #2F855A;
    color: white;
    -webkit-box-align: start;
    align-items: start;
    border-radius: 6px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
    text-align: start;
    width: auto;
    font-size: 14px;
}
.chakra-alert {
  button {
    outline: transparent solid 2px;
    outline-offset: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
    transition-duration: 200ms;
    background: transparent;
    --close-button-size: 24px;
    font-size: 10px;
    position: absolute;
    right: 4px;
    top: 4px;
  }
  div {
    flex: 1 1 0%;
    max-width: 100%;
  }
}

.chakra-alert__icon {
  color: white;
    flex-shrink: 0;
    margin-inline-end: 12px;
    width: 20px;
    height: 24px;
    display: inherit;
}
.chakra-alert button svg {
    display: inline-block;
    line-height: 16px;
    flex-shrink: 0;
    color: currentcolor;
    vertical-align: middle;
    height: 10px;
    width: 10px;
    color: white!important;
}
.chakra-alert__title {
  font-weight: 700;
    line-height: 24px;
    margin-inline-end: 8px;
}
.chakra-alert__desc {
  line-height: 24px;
  display: block;
}
.chakra-modal__content-container {
  display: flex;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1400;
  -webkit-box-pack: center;
  justify-content: center;
}
.chakra-modal__content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  outline: transparent solid 2px;
  outline-offset: 2px;
  z-index: 1400;
  max-height: 100vh;
  color: inherit;
  --drawer-bg: white;
  --drawer-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: white;
  box-shadow: var(--drawer-box-shadow);
  max-width: 320px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.chakra-modal__close-btn {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;;
  transition-duration: 200ms;
  background: #0000000A;
  border-radius: 24px;
  --close-button-size: 32px;
  font-size: 6px;
  position: absolute;
  top: 8px;
  right: 8px;

}
.chakra-modal__close-btn .chakra-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  line-height: 12px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: black;
  vertical-align: middle;
}

.chakra-modal__header {
    flex: 0 1 0%;
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #e2e8f0;
}
.chakra-modal__body {
padding: 24px 16px;

  flex: 1 1 0%;
  overflow: auto;
}
.chakra-modal__overlay {
  background: rgba(0, 0, 0, 0.48);
}
.drawer-content {
  font-family: 'Factor A';
  font-weight: 400;
}
.drawer-content__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.drawer-content__desc {
  font-size: 14px;
  color: #00000099;
  line-height: 22px;
  margin-bottom: 16px;
}
.dolyame-drawer_advantage {
  display: flex;
  align-items: center;

}
.dolyame-drawer_advantage__icon {
  margin-right: 12px;
}
.dolyame-drawer_advantage__text {
  font-size: 14px;
}
.drawer-content__paymentDetails {
  display: flex;
  background-color: #F6F7F8;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 16px;
}
.paymentDetails-item {
  flex:  0 0 25%;
  padding-right: 6px;
  box-sizing: border-box;
}
.paymentDetails-item:last-child {
  padding-right: 0;
}
.paymentDetails-item__date {
  color: #00000066;
  font-size: 12px;
  margin-bottom: 4px;
}
.paymentDetails-item__sum {
  color: #00000066;
  font-size: 13px;
  margin-bottom: 12px;
  font-weight: 500;
}
.paymentDetails-item__line .line {
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background-color: #0000001F;
}
.paymentDetails-item_active {
  .paymentDetails-item__date {
    color: black;
  }
  .paymentDetails-item__sum {
    color: black;
  }
  .paymentDetails-item__line .line {
    background-color: #428BF9;
  }
}

.btnPayDolyame {
  background-color: black;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  border-radius: 12px;
}
.widgetDolyame-btn {
  display: flex;
  background-color: black;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  font-family: 'Factor A';
  color: white;
  font-size: 13px;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
  &__badge {
    margin-right: 10px;
  }


}
.widgetDolyame-btn-content {
  align-items: center;
  display: flex;
}
.chakra-select__wrapper {
  display: flex;
  justify-content: center;
  width: 70%!important;
  margin: 0 auto;
}
.paymentMethod-select {
  padding-inline-end: 32px;
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding-inline-start: 16px;
    border-radius: 6px;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
    transition-duration: 200ms;
    padding-bottom: 1px;
    line-height: normal;
    background: #0f0f0f;
    --select-bg: white;
    --input-font-size: 16px;
    --input-padding: 16px;
    --input-border-radius: 6px;
    --input-height: 40px;
    border-width: 2px;
    border-style: solid;
    border-image: initial;
    border-color: white;
    appearance: none;
    color: white;
}
.chakra-select__icon-wrapper {
  // display: none!important;
  right: 13px;

  svg {
    color: white!important;
  }
}
.dolyamePromoWidget-basket {
  background-color: #FFCCED;
  border-radius: 8px;
  padding: 12px;
  width: 317px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 16px;
}
.dolyamePromoWidget-basket__desc {
  div:first-child {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  div:last-child {
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
  }
}
.dolyamePromoWidget-basket__logo {
  margin-right: 12px;
}
.dolyamePromoWidget-basket-content {
  display: flex;
}