#music {
    display: flex;
    flex-direction: column;
    position: relative;
        .redX {
    position: absolute;
    display: block;
    bottom: 222px;
    right: -90px;
    z-index: -1;
    }
}