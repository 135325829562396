@import '../../../styles/variables.scss';

.PaymentNavigation__text{
    color:#8B8B8B;
    display:flex;
    margin-left:70px;
    font-size: 29px;
}

.PaymentNavigation__text-order{
    color:#FFFFFF;
    margin-left: 70px;
    font-size: 48px;
}