.mobile-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    background-color: #1e1e1e;
    padding: 25px 0;

    &__policies {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;

        &-item {
            color: #73737391;
            font-size: 14px;
            text-align: center;
        }
    }

}

.mobile-footer__social {
    display: flex;
    height: fit-content;
    justify-content: center;

    &-link {
        display: flex;
        margin-left: 25px;


        &-icon {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }
    }
    &-link:first-child {
        margin-left: 0;
    }
}
.mobile-footer__policies {
    a {
        coloR: #73737391;
    }
}