@import '../../../../styles/variables.scss';

.musics__list-item {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-bottom: 35px;
    margin-right: 5%;

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__track-title {
        color: white;
        font-size: 18px;
        line-height: 22px;
    }

    &__track-type {
        color: white;
        font-size: 18px;
        line-height: 22px;
    }

    &__image {
        max-width: 290px;
    }
}