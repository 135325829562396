
.progress__stage{
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 30px;
}

.progress__stage-step{
    background-color: #4F4F4F;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.25em;
}

.progress__stage-line{
    width: 5%;
    margin:8px 10px;
}

.progress__stage-step-number{
    font-weight: 400;
    font-size: 16px;
    color: #6F6F6F;
}

.progress__stage-step.completed{
    background-color: #35AE39;
}

.progress__stage-step.completed .progress__stage-step-number{
    color: #126814;
}

.progress__stage-step.active{
    background-color: white;
}

.progress__stage-step.active .progress__stage-step-number{
    font-weight: 500;
    color: #000000;
}