@import '../../../styles/variables.scss';

.mobile-merchandise__header-logo {
    width: 100%;
    height: 60px;
    background: center center / contain no-repeat url('../../../assets/images/medium_chertochki.png');
    margin-top: 30px;
    justify-self: center;
    
    &-text {
        font-size: 36px;
        font-weight: $header-font-weight;
        color: $header-font-color;
        margin: 0;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 30px;
        text-align: center;
    }
}