
.grid__container {
    display: grid;
    grid-template-columns: 15% 25% 13% 20% 20%;
    margin-left: 70px;
}

.grid__name__text {
    color: #F72723;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid white;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.grid__name__text__centr {
    color: #F72723;
    font-weight: 500;
    font-size: 16px;
    text-align: right;
    border-bottom: 1px solid white;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.grid__name__text__right {
    color: #F72723;
    font-weight: 500;
    font-size: 16px;
    text-align: right;
    border-bottom: 1px solid white;
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.grid__name__count {
    color:#F5F5F5;
    font-weight: normal;
    font-size: 24px;
    text-align: right;
    border-bottom: 1px solid white;
    margin-bottom: 24px;
    padding-bottom: 24px;
}

.grid__name__description  {
    border-bottom: 1px solid white;
    margin-bottom: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
}

.grid__name__img {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    border-bottom: 1px solid white;
    margin-bottom: 24px;
    padding-bottom: 24px;
}

.grig__name__img-image {
    width: 180px;
    height: 180px;
    object-fit: cover;
}

.grid__name__description-text {
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin-top: 0px;
}

.grid__name__description-size {
    display: flex;
    color: white;
    font-weight: normal;
    font-size: 24px;

    &-text {
        margin-top: 5px;
        margin-right: 15px;
    }
}

.grid__selection {
    display: inline;
    width: 100px;
}

.grid__name__description-list {
    background-color: black;
}

.grid__name__description-link {
    color: #8C8C8C;
    font-weight:600;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.grid__name__quantity {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid white;
    margin-bottom: 24px;
    padding-bottom: 24px;
}

.grid__name__quanity-input {
    height: 40px;
    width: 15%;
    text-align: center;
}

.grid__name__description-list {
    background-color: white;
    border: none;
}

.grid__name__description-2 {
    color: white;
}



.grid__name__count__minus{
    line-height: 30px; 
    box-sizing: border-box; 
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
    padding-top: 0px;
    border: 1px solid #d5d5d5;
    background-color: white;
    font-family: inherit;
}

.grid__name__count__plus{
    line-height: 30px;
    box-sizing: border-box; 
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
    cursor: pointer;
    //display: flex;
    //font-size: 35px;
    //font-weight: 300;
    padding: 0px 0px;
    //margin-top: 1px;
    margin-left: 10px;
    border: 1px solid #d5d5d5;
    font-family: inherit;
}


.grid__name__count__minus__minus{
    position: relative;
    top: -2px;
}



@media screen and (max-width: 1450px) {
    html {
        font-size: 12px;
    }

    .grid__container {
        grid-template-columns: 20% 25% 13% 17% 17%;
    }

    .grid__name__description-link {
        margin: 0,
    }
}

@media screen and (max-width: 1150px) {
    html {
        font-size: 10px;
    }

    .grid__container {
        grid-template-columns: 20% 25% 13% 17% 17%;
    }

    .grid__name__description-text {

        font-size: 20px;
        
    }   
    .grid__name__description-size {
        font-size: 20px;
    }

    .grid__name__description-link {
        font-size: 14px;
    }
}

@media screen and (max-width: 1050px) {
    html {
        font-size: 10px;
    }

    .grid__name__description-text {
        font-size: 18px;
    }  
    .grid__name__description-text {
        margin-left: 10px;
        
    } 
    .grid__name__description-size {
        margin-left: 10px;
        flex-direction: column;
    }
    .grid__name__description-link {
        margin-top: 15px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 990px) {
    .grid__container {
        grid-template-columns: 24% 28% 11% 14% 14%;
    }    
}


@media screen  and (max-width: 900px) {
    .grig__name__img-image {
        width: 170px;
        height: 170px;
        object-fit: cover;
    }   
}



@media screen  and(max-width: 800px) {
    .grig__name__img-image {
        width: 160px;
        height: 160px;
        object-fit: cover;
    }
    .grid__container {
        grid-template-columns: 24% 28% 13% 14% 14%;
    }
    
    .grid__name__description-text {

        font-size: 16px;
        
    }
    .grid__name__description-size {
        font-size: 18px;
    }

    .grid__name__description-link {
        font-size: 12px;
    }
    
    .grid__name__count {
        font-size: 20px;
    }

    .grid__name__text__centr {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media screen  and(max-width: 735px) {
    .grig__name__img-image {
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
    .grid__container {
        grid-template-columns: 24% 28% 13% 14% 14%;
    }
    
    .grid__name__description-text {

        font-size: 14px;
        
    }
    .grid__name__description-size {
        font-size: 16px;
    }

    .grid__name__description-link {
        font-size: 10px;
    }
    
    .grid__name__count {
        font-size: 18px;
    }

    .grid__name__text__centr {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen  and(max-width: 670px) {
    .grig__name__img-image {
        width: 140px;
        height: 140px;
        object-fit: cover;
    }
    .grid__container {
        grid-template-columns: 24% 28% 13% 14% 14%;
    }
    
    .grid__name__description-text {

        font-size: 16px;
        
    }
    .grid__name__description-size {
        font-size: 18px;
    }

    .grid__name__description-link {
        font-size: 12px;
    }
    
    .grid__name__count {
        font-size: 20px;
    }

    .grid__name__text__centr {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}



