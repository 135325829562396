@import '../../../styles/variables.scss';

.mobile-about__header-logo {
    background: center center / contain no-repeat url('../../../assets/images/medium_chertochki.png');
    width: 35%;
    height: 50px;
    z-index: 2;
    margin-top: 30px;
    align-self: center;

    &-text {
        font-size: $mobile-concerts-header-font-size;
        font-weight: $header-font-weight;
        color: $header-font-color;
        text-align: center;
        margin: 0;
        margin-top: 15px;
    }
}