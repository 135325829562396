.merchandise-header__title {
    position: relative;
    height: 100vh;
    width: 100%;
}

.merchandise-header__title-text {
    color:#8B8B8B;
    font-size: 29px;
    margin-left: 5%;
    position: relative;
}

.merchandise-header__title-link {
    display: flex;
    justify-content: center;
    position: relative;
}

.merchandise-header__title-image {
    display: flex;
    width: 90%;
    height: auto;
    justify-content: center;
}

.merchandise-header__title-text1 {
    color:#FFFFFF;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-left: 25px;
}

.merchandise-header__controllers {
    position: absolute;
    width: 120px;
    top: 72%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
}

.merchandise-header__controllers-item {
    list-style: none;
    background-color: #585858;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;

    &.current {
        background-color: white;
    }
}

.merchandise-header__slide {
    display: flex;
    width: 90%;
    height: 70%;
    justify-content: center;
    margin: 0 auto;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);


    & > img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 16px;
    }
}

.active-anim {
    opacity: 1;
}

@media screen and (max-width: 1023px) {
    .merchandise-header__title-text1 {
        font-size: 16px;
    }

    .merchandise-header__controllers {
        top: 70%;
    }
}
