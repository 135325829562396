.header-info__container {
    background: url('../../assets/images/230213_Klepnev_10502x.jpg') no-repeat;
    background-size: cover;
    background-position: top left;
    height: 320px;
    width: 100%;
    position: relative;
}
.header-info__container {
    .redX {
        position: absolute;
        bottom: -33px;
        right: -70px;
    }
}
.header:not(.burger-opened) {
    .mobile-header-info__burger {
        filter: brightness(0.7);
    }
}