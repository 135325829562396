@import '../../styles/variables.scss';

.about {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100vh; */
    background: center / cover no-repeat url('../../assets/images/about_background.png');

    &__container {
        position: relative;
       /* height: 50vh; */
        overflow-y: visible;
        scroll-behavior: smooth;
    }

    &__information {
        z-index: 2;
        width: 45%;
        font-size: 24px;
        color: white;
        line-height: 32px;
        margin-left: 5%;
    }
}

@media screen and (max-width: 1023px) {
    .about {

        &__information {
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 800px) {
    .about {

        &__information {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 730px) {
    .about {

        &__information {
            width: 60%;
        }
    }
}