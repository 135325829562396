
.mobile__button__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile__button__payment{
    background-color: #146DF2;
    border-radius: 8px;
    width: 70%;
    height: 54px;
}

.mobile__button__basket{
    margin-top: 10px;
    background-color: #5A5A5A;
    border-radius: 8px;
    width: 70%;
    height: 54px;
}

.mobile__button__payment-text{
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}

.mobile__button__basket-text{
    font-weight: 500;
    font-size: 16px;
    color: #969696;
}

