@import '../../../styles/variables.scss';

.header-general-info__navigation {
    height: fit-content;
    width: 400px;
    display: flex;
    justify-content: space-between;
    &-link {
        height: fit-content;
        text-decoration: none;
        color: $header-font-color;
        font-size: $header-font-size;
        font-weight: $header-font-weight;
    }

    &-link:hover {
        color: #F72723;
    }

    &-link:visited {
        text-decoration: none;
    }

    // &-link:visited:hover {
    //     color: #F72723;
    // }
}

@media screen and (max-width: 1023px) {
    .header-general-info__navigation {
        margin-left: 0;
        margin-right: 2.5rem;
        width: 350px;
    }
}