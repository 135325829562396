$default-background-color: #0F0F0F;

// Header

$header-font-size: 18px;
$header-font-weight: 900;
$header-font-color: white;

// "Concerts" section

$concerts-font-size: 18px;
$concerts-font-weight: 400;
$concerts-font-color: white;
$concerts-header-font-size: 64px;
$mobile-concerts-header-font-size: 24px;

// "Merchandise" section

$merchandise-item-title-font-size: 24px;
$merchandise-item-description-font-size: 16px;
$merchandise-item-price-font-size: 24px;
$merchandise-item-prev-price-color: #aaa;
