@import "~react-image-gallery/styles/scss/image-gallery.scss";

.productBodyCart {

    &__container {
        width: 100%;
        padding: 25px 30px;
        margin-right: auto;
        margin-left: auto;
        background-color: #FFFFFF;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        

        @media (min-width: 576px) {
            max-width: 540px;
        }

        @media (min-width: 768px) {
            max-width: 720px;
        }

        @media (min-width: 1300px) {
            max-width: 900px;
        }

        @media(max-width: 576px) {
            flex-direction: column;
            box-sizing: border-box;
            padding: 17px 23px;
        }

    }

    &__title {
        display: block;
        font-size: 32px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700;
        padding-bottom: 20px;
    }

    &__half {

        &_title {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        &_gallery {
            -ms-flex: 0 0 65%;
            flex: 0 0 65%;
            max-width: 65%;
            @media(max-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 14px;
            }
        }

        &_description {
            -ms-flex: 0 0 35%;
            flex: 0 0 35%;
            max-width: 35%;
            @media(max-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

    }

    &__price {
        display: block;
        font-size: 24px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700;
        margin-bottom: 14px;
    }

}
.SizesSelect {
    display: flex;
    margin-bottom: 26px;

    &__size {
        background-color: #D9D9D9;
        padding: 12px 14px;
        margin-right: 8px;
       
        cursor: pointer;
        height: 48px;
        box-sizing: border-box;
        border-radius: 6px;
        font-weight: 700;
        text-align: center;
        border: 2px solid #D9D9D9;
    }

    &__size.selected {
        border: 2px solid #146DF2;
    }
}

.productSelectSize {
    &__label {
        font-size: 16px;
        color: #494949;
        margin-bottom: 16px;
    }

}
.productDescription {
    padding-left: 30px;
    &__addToCart {
        background-color: #F72723;
        text-align: center;
        color: #FFFFFF;
        font-weight: 700;
        padding: 17px 25.5px;
        display: inline-block;
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 25px;
        &_goToBasket {
            background-color: #FFFFFF;
            color: #F72723;
            border: 2px solid #F72723;
        }
        @media(max-width: 1300px) {
            padding: 14px 17.5px;
        }
        @media(max-width: 576px) {
            width: 100%;
            box-sizing: border-box;
        }
    }

    &__extraInfo {
        margin-bottom: 30px;
    }

    @media(max-width: 576px) {
        padding-left: 0;
    }
}

.productExtraInfo {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    display: flex;
    &__deliveryWorldwide {
        &::before {
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            background-image: url(/assets/images/box.svg);
            margin-right: 16px;
            margin-bottom: 12px;
            @media(max-width: 1300px) {
                width: 19px;
                height: 19px;
                margin-right: 12px;
                background-size: contain;
            }
        }
    }

    &__refund {
        &::before {
            content: "";
            display: block;
            width: 16px;
            height: 20px;
            background-image: url(/assets/images/change.svg);
            margin-right: 20px;
            margin-left: 3px;
            @media(max-width: 1300px) {
                width: 13px;
                height: 16.3px;
                margin-right: 15px;
                background-size: contain;
            }
        }
    }

    @media(max-width: 1300px) {
        font-size: 14px;
    }
}

.productStructure {
    &__label {
        font-weight: 900;
        font-size: 14px;
        margin-bottom: 8px;
    }
    &__desc {
        font-size: 11px;
    }
}