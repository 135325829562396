@import '../../../styles/variables.scss';

.merchandise__header-logo {
    background: url('../../../assets/images/dash_merch_desc.png') no-repeat center center;
    width: 300px;
    height: 90px;
    margin-left: 5%;

    &-text {
        font-size: $concerts-header-font-size;
        font-weight: $header-font-weight;
        color: $header-font-color;
        margin: 0;
        margin-top: 10px;
        text-align: center;
    }
}