
.input__container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.input-title{
    color:white;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.input-text{
    color:white;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.input-fio{
    width: 90%;
    height: 45px;
    border-radius: 8px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
}

.input-mail{
    width: 90%;
    height: 45px;
    border-radius: 8px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
}

.input-phone{
    width: 90%;
    height: 45px;
    border-radius: 8px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
}
.input-phone::-webkit-input-placeholder,.input-mail::-webkit-input-placeholder, .input-fio::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #B8B8B8;
}
.input-phone:-moz-placeholder, .input-mail:-moz-placeholder, .input-fio:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #B8B8B8;
}
.input-phone::-moz-placeholder,.input-mail::-moz-placeholder, .input-fio::-moz-placeholder  { /* Mozilla Firefox 19+ */
   color:    #B8B8B8;
}
.input-phone:-ms-input-placeholder,.input-mail:-ms-input-placeholder,.input-fio:-ms-input-placeholder  { /* Internet Explorer 10-11 */
   color:    #B8B8B8;
}
.input-phone::-ms-input-placeholder, .input-mail::-ms-input-placeholder, .input-fio::-ms-input-placeholder, { /* Microsoft Edge */
   color:    #B8B8B8;
}

.input-phone::placeholder,.input-mail::placeholder,.input-fio::placeholder, { /* Most modern browsers support this now. */
   color:    #B8B8B8;
}
.button__container{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.button-behind{
    height: 60px;
    width: 40%;
    border-radius: 8px;
background: white;
    text-decoration: none;
}

.button-next{
    height: 60px;
    width: 40%;
    border-radius: 8px;
    background-color: #146DF2;
    margin-left: 10px;
}

.button-next-text{
    font-weight: bold;
    font-size: 16px;
    color: white;
}

.button-behind-text{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #0F0F0F;
    margin-top: 19px;
}