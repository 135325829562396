.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 75px;

    & .pagination__container {
        width: 350px;
        display: flex;
        justify-content: space-between;
    }

    & .current {
        background-color: white;
        border: 0px;
        font-weight: 700;
    }

    & .pagination__item {
        width: 50px;
        height: 50px;
        border-radius: 8px;
        border: 1px solid #3F3F3F;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3F3F3F;
        font-size: 24px;
        font-weight: 400;
        cursor: pointer;
    }

    & .pagination__item:hover:not(.current) {
        background-color: #222;
    }

    & .current:hover {
        background-color: #ddd;
    }
}