@import '../../styles/variables.scss';

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;

    &__policies {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        width: 500px;

        &-item {
            color: #73737391;
            font-size: 16px;
        }
    }
}
.footer a {
    color: #73737391;
}

.footer__social {
    height: 100px;
    display: flex;
    height: fit-content;
    width: 300px;
    justify-content: center;

    &-link {
        display: flex;
        padding: 10px;

        &-icon {
            width: auto;
            height: 25px;
        }
    }
}