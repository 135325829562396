@import '../../../styles/variables.scss';

.musics__header-logo {
    background: url('../../../assets/images/dash_music_desc.png') no-repeat center center;
    width: 300px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    margin-left: 5%;

    &-text {
        font-size: $concerts-header-font-size;
        font-weight: $header-font-weight;
        color: $header-font-color;
        margin: 0;
        text-align: center;
    }
}