.mobile-merchandise__list-item {
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.mobile-merchandise__list-item-img {
    width: 100%;
    height: 165px;
    object-fit: cover;
    border-radius: 8px;
}

.mobile-merchandise__list-item-title {
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.mobile-merchandise__list-item__price {
    display: flex;
    margin-bottom: 15px;

    .current-price {
        color: white;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        margin-right: 10px;
    }

    .previous-price {
        color: #878787;
        font-size: 14px;
    }
}

.mobile-merchandise__list-item-buy {
    width: 100%;
    justify-self: center;
    align-self: center;
    background-color: white;
    color: #000;
    text-decoration: none;
    padding: 10px 0;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid white;

    &_goToBasket {
        background-color: #0f0f0f;
        border: 2px solid white;
        color: white;
    }
    &_notAvailable {
        width: 100%;
        justify-self: center;
        align-self: center;
        background-color: white;
        color: #000;
        text-decoration: none;
        padding: 10px 0;
        text-align: center;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 600;
        border: 2px solid white;
    }
}
.mobile-merchandise__list-item {
    &_notAvailable {
        width: 100%;
        justify-self: center;
        align-self: center;
        background-color: #DADADA;
        color: #000;
        text-decoration: none;
        padding: 10px 0;
        text-align: center;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 600;
        border: 2px solid #DADADA;
    }
}