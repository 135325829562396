@import './styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
        color: white;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
    }
}

.no-scroll {
    overflow: hidden;
}