@import '../../styles/variables.scss';

.concerts {
    background-image: url(../../assets/images/concerts_background.png);
    padding: 25px 0;
    width: 100%;
    background-size: cover;
    background-position-y: -118px;

    &-info {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        max-width: 564px;
        &__list {
        
            display: flex;
            flex-direction: column;
            max-height: 405px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: blue orange;

        }

        &__list .concerts__concert {
            &:nth-of-type(1) {
                border-bottom: 0!important;
            }
        }
    }




    &__concert {

        display: flex;

        .concerts__concert-date {
            flex: 0 0 60px;
            max-width: 60px;
        }

        .concerts__concert-city {
            flex: 0 0 150px;
            max-width: 150px;    
        }

        .concerts__concert-club {
            flex: 0 0 150px;
            max-width: 150px;
        }

        .concerts__concert-actions {
        align-items: center;
            font-size: 14px;
            display: flex;
            flex: 0 0 190px;
            justify-content: flex-end;
            max-width: 190px;

        }

        .concerts__buy-ticket {
            border: 1px solid white;
            padding: 8.15px;
            border-radius: 8px;
            display: inline-flex;
            color: white;
            cursor: pointer;
            text-decoration: none;
            transition: 0.3s;
            &:hover {
                background-color: white;
                color: black;
                .concerts__buy-ticket-text {
                    color: black;
                }
            }
            
            .concerts__buy-ticket-text {
                justify-self: center;
                align-self: center;
                text-align: center;
                margin: 0 0 0 10px;
                font-size: 16px;
            }
        }

        .concerts__vk-event-link {
            display: inline-flex;
            margin-left: 10px;

        }
        .concerts__vk-event-link > img {
            width: 35.43px;
            height: auto;
        }
        .concerts__concert-date--header {
            font-weight: 700;
        }
    }

    &__concert:nth-of-type(1) {
        grid-row: 1 / 2;
        border-bottom: 1px solid #ccc;
    }

    .separator {
        grid-row: 2 / 3;
    }

    &__concert:nth-of-type(2) {
        grid-row: 3 / 4;
    }

    &__concert:nth-of-type(3) {
        grid-row: 4 / 5;
    }

    &__concert:nth-of-type(4) {
        grid-row: 5 / 6;
    }

    &__concert:nth-of-type(5) {
        grid-row: 6 / 7;
    }

    &__concert:nth-of-type(6) {
        grid-row: 7 / 8;
    }

    &__concert:nth-of-type(7) {
        grid-row: 8 / 9;
    }

    &__concert:nth-of-type(8) {
        grid-row: 9 / 10;
    }

    &__concert:nth-of-type(9) {
        grid-row: 10 / 11;
    }

    &__concert:nth-of-type(10) {
        grid-row: 11 / 12;
    }
}